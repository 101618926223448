var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PaymentCardBase',{attrs:{"title":_vm.title,"active":_vm.active},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('section',{staticClass:"px-4"},[_c('WalletCard',{attrs:{"size":"sm","text-position":"left","ribbon":"","title-prefix":_vm.currencySymbol,"title":_vm.currentBalance,"subtitle":_vm.packageTitle}})],1),_c('section',{staticClass:"px-8 mt-6"},_vm._l((_vm.facilityTexts),function(x){return _c('feature-icon-typography',{key:x,attrs:{"text":x,"icon":"fas fa-check"}})}),1),_c('section',[_c('div',{staticClass:"grid grid-cols-2 mt-2 border-t divide-x"},[_c('div',{staticClass:"flex items-center justify-center px-4 py-6"},[_c('i',{staticClass:"fas fa-wallet text-oGreen text-28px"}),_c('div',{staticClass:"ml-4"},[_c('oto-typography',{staticClass:"block ",attrs:{"text":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.pass.passBalance'
                ),"variant":"three"}}),_c('oto-typography',{staticClass:"block",attrs:{"text":("" + _vm.currencySymbol + _vm.currentBalance),"variant":"one"}})],1)]),_c('div',{staticClass:"flex items-center justify-center px-4 py-6"},[_c('i',{staticClass:"fas fa-bicycle text-oOrange text-28px"}),_c('div',{staticClass:"ml-4"},[_c('oto-typography',{staticClass:"block ",attrs:{"text":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.pass.rideTaken'
                ),"variant":"three"}}),_c('oto-typography',{staticClass:"block",attrs:{"text":("" + _vm.totalTrips),"variant":"one"}})],1)])]),_c('div',{staticClass:"grid grid-cols-2 border-t border-b divide-x"},[_c('div',{staticClass:"flex items-center justify-center px-4 py-6"},[_c('i',{staticClass:"fas fa-calendar-alt text-oTeal text-28px"}),_c('div',{staticClass:"ml-4"},[_c('oto-typography',{staticClass:"block ",attrs:{"text":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.pass.expiryDate'
                ),"variant":"three"}}),_c('oto-typography',{staticClass:"block",attrs:{"text":("" + _vm.expiryDate),"variant":"one"}})],1)]),_c('div',{staticClass:"flex items-center justify-center px-4 py-6"},[_c('i',{staticClass:"fas fa-money-check text-oOrange text-28px"}),_c('div',{staticClass:"ml-4"},[_c('oto-typography',{staticClass:"block ",attrs:{"text":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.paymentMethod.pass.totalSpent'
                ),"variant":"three"}}),_c('oto-typography',{staticClass:"block",attrs:{"text":("" + _vm.currencySymbol + _vm.totalSpent),"variant":"one"}})],1)])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }